import React from "react"

import * as styles from "./404.module.css"
import Layout from "../components/layout"
import PageFooter from "../components/page-footer"
import SEO from "../components/seo"
import Link from "../components/link"

export const Head = () => {
  return <SEO title="404: Not found" />
}

const NotFoundPage = () => (
  <Layout footer={<PageFooter />}>
    <header className={styles.wrapper}>
      <div className={styles.shrug} />
      <h1>404</h1>
      <div>Content not found</div>
      <Link to="/">Return to home page</Link>
    </header>
  </Layout>
)

export default NotFoundPage
